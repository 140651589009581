import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  ClientConfig,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends,
  LoginFlow,
  LoginStorage
} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

const STORAGE_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-${process.env.REACT_APP_STAGE}`

type Api = ClientConfig & {
  axiosInstance: AxiosInstance
  loginFlow: LoginFlow
  loginStorage: LoginStorage
}

export const createApi = (): Api => {
  const loginStorage = new BrowserLoginStorage(STORAGE_KEY)
  const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
  const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
  const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

  const clientConfig: ClientConfig = {
    authRequestProvider,
    backendSelector,
    loginFlow,
    loginStorage
  }

  const axiosInstance = createHttpClient(clientConfig)

  return {
    ...clientConfig,
    axiosInstance
  }
}
