import React from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps
} from 'react-error-boundary'

import {ErrorDialog} from './ErrorDialog'

export type ErrorBoundaryProps = {
  apology: string
  onReset?: () => void
  resetText: string
  children?: React.ReactNode
} & Omit<ReactErrorBoundaryProps, 'fallbackRender'>

export const ErrorBoundary = ({apology, onReset, resetText, ...props}: ErrorBoundaryProps) => {
  const errorBoundaryProps = {
    ...props,
    fallbackRender: ({error, resetErrorBoundary}) => (
      <ErrorDialog
        apology={apology}
        details={error.message}
        onReset={() => {
          onReset?.()
          resetErrorBoundary()
        }}
        resetText={resetText}
      />
    )
  } as ReactErrorBoundaryProps

  return <ReactErrorBoundary {...errorBoundaryProps} />
}
