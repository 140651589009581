import {MaintainAppName, MaintainAppConfig} from '@hconnect/common/hproduce/maintain.types'

import {kms} from './kms'
import {rcfa} from './rcfa'

export const maintainApps: Partial<Record<MaintainAppName, MaintainAppConfig>> = {
  Maintain: rcfa,
  KMS: kms
}

export const maintainAllApps = Object.values(maintainApps)
