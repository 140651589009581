import {MaintainAppName} from '@hconnect/common/hproduce/maintain.types'

import {links as rcfaLinks, LinkTo} from '../../rcfa/routes'
import {Plant} from '../types/plant.types'

type AppLinks = {
  [K in MaintainAppName]: LinkTo[]
}

export const defaultDashboardLinks: LinkTo[] = [
  {
    to: '/rcfa',
    translationKey: 'Root Cause Analysis'
  },
  {
    to: '/kms',
    translationKey: 'Plant Monitoring'
  }
]

export const appLinks: AppLinks = {
  Rcfa: rcfaLinks,
  KMS: [],
  default: defaultDashboardLinks,
  Maintain: []
}

export const getNavItems = (appName: MaintainAppName, plantId: Plant['plantId'], t) => {
  return appLinks[appName].map((link) => ({
    label: t(link.translationKey),
    url: `/plant/${plantId}${link.to}`
  }))
}
