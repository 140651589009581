import {GRAPH_DETAIL_ROUTE, PLANT_ANALYSIS_LIST_ROUTE} from '@hconnect/common/consts'

export type LinkTo = {
  to: string
  translationKey: string
}

export const routes = {
  graphDetail: GRAPH_DETAIL_ROUTE,
  newAnalysis: '/plant/:plantId/rcfa/new',
  analysisList: PLANT_ANALYSIS_LIST_ROUTE,
  analysisListRedirect: '/list',
  analysisListLink: '/rcfa/list',
  newAnalysisLink: '/rcfa/new',
  tasksList: '/plant/:plantId/rcfa/tasks/list',
  tasksListLink: '/rcfa/tasks/list',
  stoppageList: '/plant/:plantId/rcfa/stoppages',
  stoppageListLink: '/rcfa/stoppages'
} as const

export const REL_ROUTES = {
  graphDetail: 'graphDetail/:analysisId/:type',
  newAnalysis: 'new',
  analysisList: 'list',
  tasksList: 'tasks/list',
  stoppageList: 'stoppages'
} as const

export const links: LinkTo[] = [
  {
    to: routes.analysisListLink,
    translationKey: 'analysisList.pageName'
  },
  {
    to: routes.tasksListLink,
    translationKey: 'tasksList.pageName'
  },
  {
    to: routes.stoppageListLink,
    translationKey: 'label.stoppageList'
  }
]
