import {AppName, WhiteList} from '@hconnect/common/hproduce/types'
import {MenuItem, Box, Autocomplete, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

type Props = {
  appName: AppName
  whiteList: WhiteList
}

export const PlantSelectDropdown: React.FC<Props> = ({whiteList, appName}) => {
  const plants = whiteList.optionsPerApp[appName]

  const sortedPlants = plants?.sort((a, b) => {
    if (a.plantName < b.plantName) {
      return -1
    }
    if (a.plantName > b.plantName) {
      return 1
    }
    return 0
  })
  const {t} = useTranslation()
  return (
    <Autocomplete
      disablePortal
      id="plant-select-box"
      options={sortedPlants ?? []}
      sx={{width: 400}}
      autoHighlight
      getOptionLabel={(option) => option.plantName}
      renderInput={(params) => (
        <TextField {...params} variant="filled" label={t('plantSelection.selectPlant')} />
      )}
      renderOption={(props, option) => (
        <Box
          component={Link}
          key={option.plantId}
          to={`plant/${option.plantId}`}
          sx={(theme) => ({
            color: theme.palette.grey['900'],
            textDecoration: 'none'
          })}
        >
          <MenuItem
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'transparent !important'
              }
            }}
          >
            {option.plantName}
          </MenuItem>
        </Box>
      )}
    />
  )
}
