import {MaintainAppConfig} from '@hconnect/common/hproduce/maintain.types'

export const kms: MaintainAppConfig = {
  name: 'KMS',
  displayName: 'Kiln monitoring',
  urlAlias: 'kms',
  whiteListedPermissionTypes: ['VIEW_KILN_MONITORING_DATA'],
  plant: {
    whiteList: ['0014']
  }
}
