import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React from 'react'

import {Content} from '@hconnect/uikit/src/lib2'

interface ErrorDialogProps {
  apology: React.ReactNode
  details: React.ReactNode
  resetText: string
  onReset: () => void
}
export const ErrorDialog: React.FC<ErrorDialogProps> = ({apology, details, onReset, resetText}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Content sx={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}} />
      <Dialog
        open={true}
        maxWidth={'lg'}
        fullScreen={fullScreen}
        aria-labelledby="error-dialog-title"
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'cneter',
              width: 1,
              height: 1
            }}
          >
            <Typography variant="h1" color="textPrimary" align="center">
              {apology}
            </Typography>
            <Box sx={{py: 2}} />
            <Typography
              variant="h3"
              color="textPrimary"
              align="center"
              noWrap={false}
              sx={{whiteSpace: 'pre-wrap'}}
            >
              {details}
            </Typography>
            <Box sx={{p: 3}}>
              <Button variant={'outlined'} onClick={onReset}>
                {resetText}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

ErrorDialog.displayName = 'ErrorDialog'
