import {Permission, PermissionType} from '@hconnect/apiclient'

export function hasPermission(
  permissions: Permission[],
  permission: PermissionType,
  plantId: string | undefined = undefined
) {
  // todo remove when role would be added
  if (permission === 'BOARD_MEMBER') {
    return true
  }

  if (!plantId) {
    return !!permissions.find(({permissionType}) => permissionType === permission)
  } else {
    const foundPermissions = permissions.filter(({permissionType}) => permissionType === permission)
    if (foundPermissions.length) {
      const plantIds = foundPermissions.map((perm) => JSON.parse(perm.dataScope).plantId)
      return plantIds.includes(plantId)
    } else {
      return false
    }
  }
}
