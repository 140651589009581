import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'

import {PlantSelect} from './dashboard/pages/PlantSelect'
import {Layout} from './shared/containers/Layout'

// Lazy imports
const Dashboard = React.lazy(() => import('./dashboard/pages/Main'))
const RCFA = React.lazy(() => import('./rcfa/App'))
const KMS = React.lazy(() => import('./kms/pages/Main'))

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PlantSelect appName="Maintain" />} />
        <Route path="plant/:plantId">
          <Route index element={<Dashboard />} />
          <Route path="rcfa/*" element={<RCFA />} />
          <Route path="kms/*" element={<KMS />} />
          <Route path="*" element={<Navigate to=".." />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
