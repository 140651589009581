import {PermissionType} from '@hconnect/apiclient'
import {waitForDOMElementToAppear} from '@hconnect/uikit/src'
import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {hasPermission} from '../hooks/usePermission'
import {routes} from '../routes'

import {ReactComponent as Illustration} from './Onboarding_illustration.svg'

type RcfaTour = 'stoppageFlowTour' | 'stoppageLinkTour'

export const useRcfaTours = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const permissions = useGlobalContext().state.permissions

  const plantId = location.pathname?.split('/')?.[2]
  const canCreateAnalysis =
    permissions && plantId
      ? hasPermission(permissions, 'CREATE_ROOT_CAUSE_FAILURE_ANALYSES' as PermissionType, plantId)
      : false

  const isDashboard = location.pathname.endsWith(plantId)
  const isRcfa = location.pathname.includes('rcfa')
  const canStartRcfaTour = canCreateAnalysis && (isDashboard || isRcfa)

  const enabledRcfaTours = useMemo(
    (): Record<RcfaTour, boolean> => ({
      stoppageFlowTour: canStartRcfaTour,
      stoppageLinkTour: canStartRcfaTour
    }),
    [canStartRcfaTour]
  )

  const rcfaTours = useMemo(
    (): Record<RcfaTour, GuidedTour> => ({
      stoppageFlowTour: {
        title: t('rcfaTours.stoppageFlowTour.title'),
        description: t('rcfaTours.stoppageFlowTour.description'),
        onTourStarted: async () => {
          navigate(routes.analysisList.replace(':plantId', plantId))
          await waitForDOMElementToAppear('#analysis-add-button')
        },
        onTourCompleted: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        onTourClosed: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        image: <Illustration />,
        steps: [
          {
            target: '#analysis-add-button',
            title: t('rcfaTours.stoppageFlowTour.step1.title'),
            content: t('rcfaTours.stoppageFlowTour.step1.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#analysis-add-button')
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear('#analysis-add-button')
            }
          },
          {
            target: '#cockpit-analysis-create-button',
            title: t('rcfaTours.stoppageFlowTour.step2.title'),
            content: t('rcfaTours.stoppageFlowTour.step2.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#analysis-add-button')
              document.getElementById('analysis-add-button')?.click()
              await waitForDOMElementToAppear('#cockpit-analysis-create-button')
            },
            onGoPrevToStep: async () => {
              navigate(routes.analysisList.replace(':plantId', plantId))
              await waitForDOMElementToAppear('#analysis-add-button')
              document.getElementById('analysis-add-button')?.click()
              await waitForDOMElementToAppear('#cockpit-analysis-create-button')
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])",
            title: t('rcfaTours.stoppageFlowTour.step3.title'),
            content: t('rcfaTours.stoppageFlowTour.step3.text'),
            placement: 'left',
            onGoNextToStep: async () => {
              navigate(routes.stoppageList.replace(':plantId', plantId) + '?page=0&rows=100')
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
            },
            onGoPrevToStep: async () => {
              navigate(routes.stoppageList.replace(':plantId', plantId) + '?page=0&rows=100')
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='analysis-create-btn']",
            title: t('rcfaTours.stoppageFlowTour.step3a.title'),
            content: t('rcfaTours.stoppageFlowTour.step3a.text'),
            placement: 'left',
            // disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              const el = document.querySelector(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            },
            onGoPrevToStep: async () => {
              navigate(routes.stoppageList.replace(':plantId', plantId) + '?page=0&rows=100')
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              const el = document.querySelector(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            }
          },
          {
            target: '#create-analysis-form',
            title: t('rcfaTours.stoppageFlowTour.step4.title'),
            content: t('rcfaTours.stoppageFlowTour.step4.text'),
            placement: 'right',
            onGoNextToStep: async () => {
              navigate(`${routes.newAnalysis.replace(':plantId', plantId)}?type=stoppage`, {
                state: {
                  selectedStoppage: {
                    id: 's_6102',
                    plantId: '0014',
                    stoppageCode: {
                      code: 'S917',
                      text: 'Mill - Process',
                      type: 'mill'
                    },
                    equipment: {
                      id: '02MM105',
                      text: 'Gummibandfoerderer  /Rohmehlband RM 1'
                    },
                    title: '02MM105 Lagerschaden',
                    description: 'Gurtförderer 02MM105 Umlenktrommel Lagerschaden',
                    start: '2023-04-08T12:00:00Z',
                    end: '2023-04-08T20:00:00Z',
                    duration: 28800,
                    createdBy: 'Thomas Wild',
                    createdById: '51164c38-1dae-4f70-bb51-d8401556b025',
                    updatedBy: 'Thomas Wild',
                    updatedById: '51164c38-1dae-4f70-bb51-d8401556b025',
                    createDate: '2023-04-08T19:04:15.778463Z',
                    updateDate: '2023-04-08T19:04:32.713941Z'
                  }
                }
              })
              await waitForDOMElementToAppear('#create-analysis-form')
            }
          },
          {
            target: '#create-analysis-save-button',
            title: t('rcfaTours.stoppageFlowTour.step5.title'),
            content: t('rcfaTours.stoppageFlowTour.step5.text'),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#create-analysis-save-button')
            }
          }
        ]
      },
      stoppageLinkTour: {
        title: t('rcfaTours.stoppageLinkTour.title'),
        description: t('rcfaTours.stoppageLinkTour.description'),
        onTourStarted: async () => {
          navigate(routes.stoppageList.replace(':plantId', plantId) + '?page=0&rows=100')
          await waitForDOMElementToAppear("[data-test-id='stoppage-list-title']")
        },
        onTourCompleted: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        onTourClosed: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        image: <Illustration />,
        steps: [
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='stoppage-list-title']",
            title: t('rcfaTours.stoppageLinkTour.step1.title'),
            content: t('rcfaTours.stoppageLinkTour.step1.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='stoppage-list-title']")
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='stoppage-list-title']")
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])",
            title: t('rcfaTours.stoppageLinkTour.step2.title'),
            content: t('rcfaTours.stoppageLinkTour.step2.text'),
            placement: 'left',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='analysis-link-btn']",
            title: t('rcfaTours.stoppageLinkTour.step3.title'),
            content: t('rcfaTours.stoppageLinkTour.step3.text'),
            disableScrolling: true,
            placement: 'left',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              const el = document.querySelector(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='back-btn']")
              const back = document.querySelector("[data-test-id='back-btn']")
              back?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              const el = document.querySelector(
                "[data-guided-tour-id='stoppage-pending-btn']:not([disabled])"
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='link-analysis-dialog-title']",
            title: t('rcfaTours.stoppageLinkTour.step4.title'),
            content: t('rcfaTours.stoppageLinkTour.step4.text'),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='analysis-link-btn']")
              const el = document.querySelector("[data-test-id='analysis-link-btn']")
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear("[data-test-id='analysis-title']")
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='link-analysis-search']",
            title: t('rcfaTours.stoppageLinkTour.step5.title'),
            content: t('rcfaTours.stoppageLinkTour.step5.text'),
            placement: 'auto',
            disableScrolling: true
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='analysis-title']",
            title: t('rcfaTours.stoppageLinkTour.step6.title'),
            content: t('rcfaTours.stoppageLinkTour.step6.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='analysis-title']")
              const el = document.querySelector("[data-test-id='analysis-title']")
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: "[data-test-id='select-analysis-btn']",
            title: t('rcfaTours.stoppageLinkTour.step7.title'),
            content: t('rcfaTours.stoppageLinkTour.step7.text'),
            disableScrolling: true,
            placement: 'top',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-test-id='select-analysis-btn']")
            }
          }
        ]
      }
    }),
    [navigate, plantId, t]
  )

  return {rcfaTours, enabledRcfaTours}
}
