import {buttonOnBlueSx} from '@hconnect/uikit/src/lib2'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration500.svg'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {Button, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const GeneralError = ({
  onGoBack,
  description
}: {
  onGoBack: () => void
  description: string
}) => {
  const {t} = useTranslation()

  return (
    <BaseErrorPage
      caption={t('error.500.caption')}
      description={description}
      illustration={<Illustration />}
    >
      <Stack direction="row" spacing={2}>
        <Button sx={buttonOnBlueSx} startIcon={<ArrowBack />} onClick={onGoBack}>
          {t('buttons.back')}
        </Button>
      </Stack>
    </BaseErrorPage>
  )
}
