/** @jsxImportSource @emotion/react */
import {ClassNames} from '@emotion/react'
import {apps, Permission, WhiteList} from '@hconnect/common/hproduce'
import {AppName, Env} from '@hconnect/common/hproduce/types'
import {addToWhiteList, whiteListByPermission} from '@hconnect/common/hproduce/whiteListCheck'
import {KeyboardArrowDown} from '@mui/icons-material'
import {Box, Typography, useTheme, Link, Divider, Badge} from '@mui/material'
import React, {ReactNode, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {AppLogo} from '../../../common'
import {GuidedTour} from '../../components/guidedTour/types'
import {GuidedTourStatus} from '../../components/guidedTour/types/GuidedTour.enums'
import {useIsMobile, useNavigationLinkStyles} from '../../hooks'
import {AppSelectPopUp} from '../AppSelect'
import {Plant} from '../PlantSelect'
import {NavItem, User} from '../types'

import {HProduceAccountDropdown} from './accountDropdown/HProduceAccountDropdown'
import {BurgerMenu} from './BurgerMenu'
import {getIsLinkActive} from './helpers'
import {DropdownFooterProps} from './accountDropdown/components/DropdownFooter'

export type {NavItem, User} from '../types'

export type CommonHeaderGuidedTourProps = {
  tours: Record<string, GuidedTour>
  statuses: Record<string, GuidedTourStatus>
}

export type CommonHeaderProps = {
  appName: AppName
  plant?: Plant
  navItems?: NavItem[]
  supportedLanguages: string[]
  closeDropdownOnLanguageSwitch?: boolean
  user?: User
  onNavItemClick?: (route: string) => void
  onNavItemHover?: (route: string) => void
  // function from react-router-dom v5 or v6 push or navigate
  navigate: (route: string) => void
  locationPath: string
  enableMyTasks?: boolean
  guidedTours?: CommonHeaderGuidedTourProps
  additionalActions?: ReactNode[]
  footer?: DropdownFooterProps
} & (
  | {
      disableAppSelect: true
    }
  | {
      permissions: Permission[]
      env: Env
    }
  | {
      whiteList: WhiteList
      env: Env
    }
) & {
    onLogout?: () => void
    user?: User
  }

const getWhiteListFromProps = (props: CommonHeaderProps) => {
  if ('disableAppSelect' in props) return

  let whiteList: WhiteList | undefined = undefined
  if ('whiteList' in props && props.whiteList) {
    whiteList = props.whiteList
  } else if ('permissions' in props && props.permissions) {
    whiteList = whiteListByPermission(props.env, props.permissions)
  }

  // this is a temporary safety net until the permission check is bulletproof,
  // it will make sure that at least app the user is currently in, is available in the list

  const {plant, appName, env} = props
  const plantId = plant?.plantId
  const appConfig = apps[appName]
  if (plantId && whiteList && !whiteList.optionsMap.has(appName + plantId) && appConfig) {
    addToWhiteList(env, whiteList, appConfig, plantId)
  }

  return whiteList
}

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const {
    appName,
    navItems,
    plant,
    onNavItemClick,
    onNavItemHover,
    user,
    onLogout,
    navigate,
    locationPath,
    guidedTours,
    enableMyTasks,
    additionalActions,
    closeDropdownOnLanguageSwitch,
    footer
  } = props
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const {activeLink, disabledLink, link, desktopLink} = useNavigationLinkStyles()
  const language = i18n.language
  const localesList = props.supportedLanguages.map((code) => ({
    code,
    name: t(`locales.${code}`)
  }))
  const isMobile = useIsMobile()

  const whiteList = useMemo(
    () => getWhiteListFromProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props['disableAppSelect'], props['whiteList'], props['permissions']]
  )
  // disable the app select only when prop is sent or user doesn't have permissions to see any app
  const disableAppSelect = !whiteList

  // Sub navigation
  const ref = React.useRef<HTMLDivElement>(null)
  const [subNavOpenLabel, setSubNavOpenLabel] = React.useState<string | undefined>(undefined)
  const handleToggleSubNav = (key?: string) => {
    if (key === subNavOpenLabel) {
      setSubNavOpenLabel(undefined)
    } else {
      setSubNavOpenLabel(key)
    }
  }

  useEffect(() => {
    // close dropdown on click outside of the box on the page
    const handler = (event: MouseEvent | TouchEvent) => {
      if (setSubNavOpenLabel && !ref.current?.contains(event.target as Node)) {
        setSubNavOpenLabel(undefined)
      }
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      // cleanup the event listener
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [setSubNavOpenLabel])

  return (
    <Box display="flex" width="100%">
      <Box display="flex" flex={1} data-test-id="common-header">
        <Box
          component={'nav'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: 'auto'
            }
          }}
        >
          <ClassNames>
            {({css}) => (
              <>
                {isMobile ? (
                  <BurgerMenu
                    disableAppSelect={disableAppSelect}
                    navItems={navItems}
                    activeAppName={appName}
                    activePlantId={plant?.plantId}
                    whiteList={whiteList}
                    onNavItemClick={onNavItemClick}
                    navigate={navigate}
                    locationPath={locationPath}
                  />
                ) : (
                  !disableAppSelect && (
                    <AppSelectPopUp
                      activeAppName={appName}
                      activePlantId={plant?.plantId}
                      whiteList={whiteList}
                    />
                  )
                )}

                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    [theme.breakpoints.up('sm')]: {
                      width: 'auto',
                      justifyContent: 'flex-start'
                    }
                  })}
                >
                  <Link
                    onClick={() => navigate?.('/')}
                    sx={{
                      textDecoration: 'none'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mr: {md: 2}
                      }}
                      data-test-id="common-header-logo"
                    >
                      <AppLogo
                        name={appName}
                        className={css({
                          marginRight: 8
                        })}
                      />
                      {appName !== 'HProduce' && (
                        <div>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            sx={{lineHeight: '20px', fontSize: 16}}
                          >
                            {appName}
                          </Typography>
                          {plant?.plantName && (
                            <Typography
                              variant="h4"
                              color="secondary"
                              sx={{
                                fontSize: 12,
                                lineHeight: '16px',
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {plant.plantName}
                            </Typography>
                          )}
                        </div>
                      )}
                    </Box>
                  </Link>
                </Box>
                {!isMobile && (
                  <Box sx={{display: 'flex'}} data-test-id="desktop-menu">
                    {navItems?.map(
                      (
                        {
                          label,
                          url,
                          dataTestId,
                          isDisabled,
                          subItems,
                          activateOnlyIfExact,
                          showBadge,
                          id
                        },
                        index
                      ) => {
                        const isLinkActive = getIsLinkActive(url, locationPath, activateOnlyIfExact)
                        return (
                          <div key={label} id={id ?? `nav-item-${index}`}>
                            {subItems && subItems.length > 0 && subNavOpenLabel === label ? (
                              <Box
                                ref={ref}
                                bgcolor="background.paper"
                                color="text.primary"
                                borderRadius={1}
                                marginBottom={1}
                                sx={{
                                  position: 'absolute',
                                  left: 'auto',
                                  right: 'auto',
                                  top: '125%',
                                  boxShadow:
                                    '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.12)',
                                  textAlign: 'center'
                                }}
                                data-test-id={`${dataTestId}-list`}
                              >
                                {subItems.map((subItem, index) => {
                                  const isSubLinkActive = getIsLinkActive(
                                    subItem.url,
                                    locationPath,
                                    subItem.activateOnlyIfExact
                                  )
                                  return (
                                    <React.Fragment key={subItem.label}>
                                      <Link
                                        component="button"
                                        onClick={() => {
                                          setSubNavOpenLabel(undefined)
                                          onNavItemClick?.(subItem.url)
                                          navigate?.(subItem.url)
                                        }}
                                        onMouseEnter={() => onNavItemHover?.(subItem.url)}
                                        style={{
                                          ...link,
                                          ...desktopLink,
                                          ...(subItem.isDisabled && disabledLink),
                                          ...(isSubLinkActive && activeLink),
                                          padding: '16px',
                                          margin: 0
                                        }}
                                        className={isLinkActive ? 'active' : undefined}
                                        data-test-id={subItem.dataTestId}
                                      >
                                        {subItem.label}
                                      </Link>
                                      {index < subItems.length - 1 && (
                                        <Divider orientation="horizontal" color="soft" />
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </Box>
                            ) : null}
                            <Link
                              component="button"
                              onClick={() => {
                                onNavItemClick?.(url)
                                navigate?.(url)
                              }}
                              onMouseEnter={() => onNavItemHover?.(url)}
                              style={{
                                ...link,
                                ...desktopLink,
                                ...(isDisabled && disabledLink),
                                ...(isLinkActive && activeLink)
                              }}
                              className={isLinkActive ? 'active' : undefined}
                              data-test-id={dataTestId}
                            >
                              {showBadge ? (
                                <Badge color="primary" variant="dot">
                                  {label}
                                </Badge>
                              ) : (
                                label
                              )}
                            </Link>

                            {subItems && subItems.length > 0 && (
                              <KeyboardArrowDown
                                onClick={() => handleToggleSubNav(label)}
                                style={{cursor: 'pointer', verticalAlign: 'middle'}}
                                data-test-id={`${dataTestId}-dropdown-arrow`}
                              />
                            )}
                          </div>
                        )
                      }
                    )}
                  </Box>
                )}
              </>
            )}
          </ClassNames>
        </Box>
      </Box>

      <Box display="flex" gap={1}>
        {additionalActions}
        {user && onLogout && (
          <HProduceAccountDropdown
            actions={{
              logout: onLogout,
              selectLanguage: (lng: string) => void i18n.changeLanguage(lng)
            }}
            logoutButtonText={t('logout')}
            locales={{
              defaultLocale: language,
              locales: localesList
            }}
            profile={{
              name: user.name ?? '',
              email: user.email ?? ''
            }}
            guidedTours={guidedTours}
            enableMyTasks={enableMyTasks}
            closeDropdownOnLanguageSwitch={closeDropdownOnLanguageSwitch}
            footer={footer}
          />
        )}
      </Box>
    </Box>
  )
}
