import {Error403Page, Error404Page} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom'

import {useGlobalContext} from '../hooks/useGlobalContext'

import {GeneralError} from './GeneralError'

export const MaintainErrorFallback = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {plantId} = useParams()
  const onLogout = useGlobalContext().state.onLogout

  const errorDetails = `@error_details: ${
    error?.response?.data?.detail || error?.message || 'No details available'
  }`

  const handleGoBack = () => {
    resetErrorBoundary()
    if (plantId) {
      navigate(`/plant/${plantId}`, {replace: true})
    } else {
      navigate('/', {replace: true})
    }
  }

  switch (error?.response?.status) {
    case 403:
      return (
        <Error403Page
          caption={t('error.403.caption')}
          description={`${t('error.403.description')} ${errorDetails}`}
          onLogout={onLogout}
          onGoBack={handleGoBack}
          goBackCaption={t('buttons.back')}
        />
      )
    case 404:
      return (
        <Error404Page
          caption={t('error.404.caption')}
          description={`${t('error.404.description')} ${errorDetails}`}
          RouterLink={RouterLink}
          onGoBack={handleGoBack}
          goBackCaption={t('buttons.back')}
        />
      )
    default:
      return (
        <GeneralError
          onGoBack={handleGoBack}
          description={`${t('error.500.description')} ${errorDetails}`}
        />
      )
  }
}
