import {Permission, RoleType} from '@hconnect/apiclient'
import {WhiteList} from '@hconnect/common/hproduce'
import React from 'react'

export interface State {
  user: {
    email: string
    name: string
    userId: string
    countryCode: string
    roles?: RoleType[]
  }
  whiteList: WhiteList
  permissions?: Permission[]
  onLogout: () => void
}

export interface GlobalContext {
  state: State
  setState: React.Dispatch<React.SetStateAction<State>>
}

export interface ProviderProps {
  initialState: State
  children?: React.ReactNode
}

// creating the context
const context = React.createContext<GlobalContext | undefined>(undefined)

// can be used to wrap the <App /> but also to wrap containers within tests
export const GlobalContextProvider: React.FC<ProviderProps> = ({initialState, children}) => {
  const [state, setState] = React.useState<State>(initialState)

  return (
    <context.Provider
      value={{
        state,
        setState
      }}
    >
      {children}
    </context.Provider>
  )
}

// used by consumers
export function useGlobalContext(): GlobalContext {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useGlobalContext was used outside the scope of a GlobalContextProvider!')
  } else {
    return ctx
  }
}
