import {MaintainAppConfig} from '@hconnect/common/hproduce/maintain.types'
import {WhiteList} from '@hconnect/common/hproduce/types'
import {useMemo} from 'react'

import {maintainApps} from '../../shared/config'

export const allApps = Object.values(maintainApps)

export const useAppsByWhiteList: (
  whiteList: WhiteList,
  plantId?: string
) => Record<'allowed' | 'unAllowed', MaintainAppConfig[]> = (whiteList, plantId) => {
  const {maintainApps} = whiteList
  return useMemo(() => {
    if (plantId) {
      const optionsPerPlant = {}
      maintainApps[plantId]?.map((app) => {
        optionsPerPlant[app.appName] = app
        return 1
      })

      return {
        allowed: allApps.filter((app) => optionsPerPlant[app.name]),
        unAllowed: allApps.filter((app) => !optionsPerPlant[app.name])
      }
    } else {
      return {
        allowed: allApps.filter((app) => maintainApps[app.name]),
        unAllowed: allApps.filter((app) => !maintainApps[app.name])
      }
    }
  }, [maintainApps, plantId])
}
