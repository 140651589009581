import {AppName} from '@hconnect/common/hproduce/types'
import {Grid, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import SelectPlant from '../assets/SelectPlant.svg'
import {PlantSelectDropdown} from '../components/PlantSelectDropdown'

type Props = {
  appName: AppName
}

export const PlantSelect: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {appName} = props
  const whitelist = useGlobalContext().state.whiteList

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          src={SelectPlant}
          alt="Select-Plant-Img"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              width: '373px'
            }
          })}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '335px'
          }}
        >
          <Box
            sx={{
              fontSize: '28px',
              fontWeight: 700,
              marginBottom: 2
            }}
          >
            {t('plantSelection.selectAPlant')}
          </Box>
          <Box
            sx={{
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: '16px',
              lineHeight: '22px',
              marginBottom: 4
            }}
          >
            {t('plantSelection.desc')}
          </Box>
          <PlantSelectDropdown whiteList={whitelist} appName={appName} />
        </Box>
      </Box>
    </Grid>
  )
}
