import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {AxiosInstance} from 'axios'
import React from 'react'

const context = React.createContext<Api | undefined>(undefined)
const queryClient = new QueryClient()

export type Api = {
  axiosInstance: AxiosInstance
  // Add all generic properties here which are to be shared in app
}

type Props = {
  axiosInstance: AxiosInstance
  children: React.ReactNode
}

// can be used to wrap the <App /> but also to wrap containers within tests
export const ApiContextProvider: React.FC<Props> = ({axiosInstance, children}) => {
  return (
    <context.Provider value={{axiosInstance}}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </context.Provider>
  )
}

// used by consumers
export function useApi(): Api {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useApi was used outside the scope of an ApiContextProvider!')
  } else {
    return ctx
  }
}

// direct axios instance usage using useApi context
export const useAxios = () => useApi().axiosInstance
