import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {hpTheme, ErrorBoundary} from '@hconnect/uikit/src/lib2'
import {Box, CircularProgress, CssBaseline, ThemeProvider} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import type {AxiosInstance} from 'axios'
import {SnackbarProvider} from 'notistack'
import {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {ApiContextProvider} from '../hooks/useApi'

import {MaintainToursProvider} from './MaintainToursProvider'

export const AppProviders = ({
  children,
  axiosInstance
}: {
  children: React.ReactNode
  axiosInstance: AxiosInstance
}) => {
  return (
    <ThemeProvider theme={hpTheme}>
      <BrowserRouter>
        <Suspense
          fallback={
            <Box
              sx={{
                display: 'flex',
                height: '100vh',
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress color="secondary" size={80} />
            </Box>
          }
        >
          <ErrorBoundary apology="Something went wrong." resetText="Click here to refresh">
            <CssBaseline />
            <ApiProvider secureApi={axiosInstance} publicApi={axiosInstance}>
              <ApiContextProvider axiosInstance={axiosInstance}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <SnackbarProvider>
                    <MaintainToursProvider>{children}</MaintainToursProvider>
                  </SnackbarProvider>
                </LocalizationProvider>
              </ApiContextProvider>
            </ApiProvider>
          </ErrorBoundary>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}
