import {CloseOutlined} from '@mui/icons-material'
import {Button, Typography, Stack, BoxProps, IconButton, Box} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocalStorage} from 'react-use'

import {mergeSx} from '../../../utils'
import {CardBox} from '../../containers'
import {useGuidedTours} from '../hooks'
import {GuidedTourStatus} from '../types/GuidedTour.enums'

/**
 * Component to be place into product layout, if there are some new tour
 * this banner will be activated
 */

type TourBannerProps = Pick<BoxProps, 'sx'> & {rejectionTimeHours?: number}
const tourBannerRejectedUntil = 'tourBannerRejectedUntil'

// closing the banner rejects it for next 24 hours by default
const REJECTION_INTERVAL_HOURS = 24

export const TourBanner: React.FC<TourBannerProps> = ({
  sx,
  rejectionTimeHours = REJECTION_INTERVAL_HOURS
}) => {
  const {t} = useTranslation()
  const {tours, activeTour, setActiveTour, tourStatuses} = useGuidedTours()
  const newTours = Object.entries(tours).filter(
    ([tourKey]) => tourStatuses?.[tourKey] === GuidedTourStatus.NEW
  )
  const [rejectedUntilISO, setRejectedUntilISO] = useLocalStorage<string>(tourBannerRejectedUntil)
  const rejectedUntilUTC = rejectedUntilISO ? moment.utc(rejectedUntilISO) : undefined
  const isBannerRejected = rejectedUntilUTC ? moment.utc().isBefore(rejectedUntilUTC) : false

  const shouldHideBanner = activeTour || newTours.length === 0 || isBannerRejected

  if (shouldHideBanner) {
    return null
  }
  const [displayedTourKey, displayedTour] = newTours[0]

  const handleRejectBanner = () => {
    setRejectedUntilISO(moment.utc().add(rejectionTimeHours, 'hours').toISOString())
  }
  const handleStartTour = () => {
    setActiveTour(displayedTourKey)
  }

  return (
    <>
      <CardBox sx={mergeSx({backgroundColor: ({palette}) => palette.grey[900]}, sx)}>
        <Stack direction="row" spacing={3} justifyContent="flex-end">
          {displayedTour?.image && (
            <Box sx={({spacing}) => ({maxHeight: spacing(10), maxWidth: spacing(12), mr: 3})}>
              {displayedTour.image}
            </Box>
          )}
          <Stack spacing={1.5} sx={{width: 1}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h3" sx={{color: ({palette}) => palette.common.white}}>
                {displayedTour.title}
              </Typography>
              <Stack direction="row" spacing={3}>
                <Button variant="contained" onClick={handleStartTour}>
                  {t('guidedTours.startTour')}
                </Button>
                <IconButton
                  sx={{color: ({palette}) => palette.common.white}}
                  onClick={handleRejectBanner}
                >
                  <CloseOutlined />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="body1" sx={{color: ({palette}) => palette.common.white}}>
              {displayedTour.description}
            </Typography>
          </Stack>
        </Stack>
      </CardBox>
    </>
  )
}
